import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	TooltipProps,
	Typography,
	styled,
	tooltipClasses,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { DriftDetection, Environment } from "../../api";
import { EnvironmentCardDetail } from "./EnvironmentCardDetail";

export type EnvironmentCardProps = {
	environment: Environment;
	filteredApplications: string[];
	handleDelete: (id: string, comment?: string) => void;
};

const NoTextWrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: "none",
		fontSize: 14,
	},
});

export const EnvironmentCard = ({
	environment,
	filteredApplications,
	handleDelete,
}: EnvironmentCardProps) => {
	const { palette } = useTheme();

	const [manifestNames, setManifestNames] = useState<string[]>([]);
	const [applicationNames, setApplicationNames] = useState<string[]>([]);
	const [openDetail, setOpenDetail] = useState<boolean>(false);
	useEffect(() => {
		const names: string[] = [];
		const applications: string[] = [];
		environment.manifests.forEach((r) => {
			names.push(r.version);
			const serviceNames = r.services.map((service) => service.name);
			applications.push(...serviceNames);
		});
		setManifestNames(names);
		setApplicationNames(applications);
	}, [filteredApplications]);

	const handleClose = () => {
		setOpenDetail(false);
	};

	const checkManifestDifferences = (driftDetection: DriftDetection) => {
		if (driftDetection.unexpectedComponents.length > 0) {
			return true;
		}
		return driftDetection.components.some(
			(component) => component.expectedVersion !== component.actualVersion
		);
	};

	const hasManifestDifferences = environment.driftDetection
		? checkManifestDifferences(environment.driftDetection)
		: false;

	if (
		filteredApplications.length > 0 &&
		!applicationNames.some((item) => filteredApplications.includes(item))
	) {
		return <></>;
	}

	return (
		<Box>
			<Card style={{ marginTop: "10px" }}>
				<CardActionArea
					onClick={(e) => {
						setOpenDetail(true);
						e.preventDefault();
					}}
				>
					<CardContent>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Typography
								sx={{
									fontSize: 20,
									fontWeight: "bold",
									display: "inline",
								}}
							>
								{environment.label}
							</Typography>
							{hasManifestDifferences && (
								<Link to="/versions" state={{ environmentId: environment._id }}>
									<NoTextWrapTooltip
										title={`Components versions differ from manifest. Click for detailed info`}
									>
										<IconButton>
											<WarningAmberIcon color={"warning"} />
										</IconButton>
									</NoTextWrapTooltip>
								</Link>
							)}
						</div>

						<Typography
							sx={{
								fontSize: 15,
								padding: "5px",
								textAlign: "center",
							}}
						>
							{environment.description}
						</Typography>
						{manifestNames.length > 0 && (
							<List>
								{manifestNames.map((m) => (
									<ListItem key={`manifest-${m}`}>
										<ListItemIcon sx={{ minWidth: "25px" }}>
											<InsertDriveFileIcon
												sx={{
													color: "white",
													backgroundColor: palette.primary.main,
													borderRadius: "25%",
													fontSize: "15px",
												}}
											/>
										</ListItemIcon>
										<ListItemText
											primaryTypographyProps={{ style: { fontSize: "15px" } }}
											primary={m}
										/>
									</ListItem>
								))}
							</List>
						)}
					</CardContent>
				</CardActionArea>
			</Card>
			{openDetail && (
				<EnvironmentCardDetail
					open={openDetail}
					environment={environment}
					filteredApplications={filteredApplications}
					manifestNames={manifestNames}
					onClose={handleClose}
					handleDelete={handleDelete}
				></EnvironmentCardDetail>
			)}
		</Box>
	);
};
