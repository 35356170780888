import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Chip,
	Grid,
	IconButton,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Spinner } from "@unified-trials/arcane-ui-tool";
import React from "react";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

import { Environment, ManifestHistory } from "../../../api";
import { DATETIME_FORMAT, formatDate } from "../../../utils";

export interface ManifestRowProps {
	id: string;
	isExpanded: boolean;
	version: string;
	environments: Environment[];
	onChange: (
		panel: string
	) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
	openComponentJsonView: boolean;
	jsonViewTitle: string;
	componentJsonViewData: unknown;
	isLoading: boolean;
	manifestHistories: ManifestHistory[];
	onManifestViewClick: (id: string, revision: number) => () => void;
	onCloseManifestView: () => void;
}

export const ManifestRow = (props: ManifestRowProps) => {
	const getEnvironmentLabelsByManifestId = (manifestId: string): string[] => {
		const environmentNames: string[] = [];
		props.environments.forEach((environment) => {
			const manifest = environment.manifests.find((m) => m.id === manifestId);
			if (manifest) {
				environmentNames.push(environment.label);
			}
		});

		return environmentNames;
	};

	return (
		<Accordion
			key={props.id}
			sx={{ m: 1 }}
			expanded={props.isExpanded}
			onChange={props.onChange(props.id)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1-content"
				id="panel1-header"
			>
				<Typography m={1}>{props.version}</Typography>
				<Stack direction="row" spacing={1}>
					{getEnvironmentLabelsByManifestId(props.id).map((e) => (
						<Chip key={e} label={e} size={"medium"} color={"info"} />
					))}
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				{props.isExpanded && (
					<Grid container>
						<Grid item xs={12}></Grid>
						<Grid item xs={12}>
							{props.openComponentJsonView && (
								<Dialog
									open={props.openComponentJsonView}
									onClose={props.onCloseManifestView}
									fullWidth={true}
									maxWidth={"xl"}
								>
									<DialogTitle id="alert-dialog-title">
										{props.jsonViewTitle}
									</DialogTitle>
									<Tooltip title="Close">
										<IconButton
											aria-label="close"
											onClick={props.onCloseManifestView}
											sx={{
												position: "absolute",
												right: 8,
												top: 8,
												color: (theme) => theme.palette.grey[500],
											}}
										>
											<CloseIcon />
										</IconButton>
									</Tooltip>
									<DialogContent style={{ minWidth: 300 }}>
										<JsonView
											data-testid={"manifest-json-viewer"}
											src={props.componentJsonViewData}
										/>
									</DialogContent>
								</Dialog>
							)}
							<TableContainer component={Paper}>
								{props.isLoading && <Spinner fullscreen opacity={0.5} />}
								<Table
									sx={{ minWidth: { xs: 250, sm: 650 } }}
									aria-label="simple table"
								>
									<TableHead>
										<TableRow>
											<TableCell align="right">Revision</TableCell>
											<TableCell
												sx={{ display: { xs: "none", sm: "table-cell" } }}
											>
												Author
											</TableCell>
											<TableCell
												sx={{ display: { xs: "none", sm: "table-cell" } }}
											>
												Created At
											</TableCell>
											<TableCell
												sx={{ display: { xs: "none", sm: "table-cell" } }}
											>
												Updated At
											</TableCell>
											<TableCell
												sx={{
													display: { xs: "none", sm: "table-cell" },
												}}
											>
												Comment
											</TableCell>
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{props.manifestHistories.map((row) => (
											<TableRow
												key={row.revision}
												sx={{
													"&:last-child td, &:last-child th": {
														border: 0,
													},
												}}
											>
												<TableCell align="right" component="th" scope="row">
													{row.revision}{" "}
													{row.revision === props.manifestHistories.length
														? "(Current)"
														: ""}
												</TableCell>
												<TableCell
													sx={{ display: { xs: "none", sm: "table-cell" } }}
												>
													{row.author}
												</TableCell>
												<TableCell
													sx={{ display: { xs: "none", sm: "table-cell" } }}
												>
													{formatDate(new Date(row.createdAt), DATETIME_FORMAT)}
												</TableCell>
												<TableCell
													sx={{ display: { xs: "none", sm: "table-cell" } }}
												>
													{formatDate(new Date(row.updatedAt), DATETIME_FORMAT)}
												</TableCell>
												<TableCell
													sx={{ display: { xs: "none", sm: "table-cell" } }}
												>
													{row.comment}
												</TableCell>
												<TableCell>
													<Tooltip title="View Manifest's Components">
														<IconButton
															sx={{
																color: "primary",
																float: "right",
																marginBottom: "20px",
																marginRight: "10px",
															}}
															onClick={props.onManifestViewClick(
																props.id,
																row.revision
															)}
															data-testid={"manifest-view-open-button"}
														>
															<PlagiarismIcon
																fontSize={"medium"}
																color="primary"
															/>
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				)}
			</AccordionDetails>
		</Accordion>
	);
};
