import { SortableHeaders } from "../../../constants";
import {
	ManifestComponent,
	ManifestComponentListFilters,
} from "../../../types";

export const filterManifestComponents = (
	manifestComponents: ManifestComponent[],
	filters: ManifestComponentListFilters
): ManifestComponent[] => {
	const { searchValue } = filters;
	return manifestComponents.filter((manifestComponent) => {
		return searchValue ? manifestComponent.name.includes(searchValue) : true;
	});
};

export const compareComponents = (
	a: ManifestComponent,
	b: ManifestComponent,
	orderBy: SortableHeaders,
	comparator: (a?: number | string, b?: number | string) => number
) => {
	if (orderBy === "app") {
		return comparator(a.appName, b.appName);
	}

	if (orderBy === "manifestVersion") {
		return comparator(a.manifestVersion, b.manifestVersion);
	}

	if (orderBy === "name") {
		return comparator(a.name, b.name);
	}
	if (orderBy === "version") {
		return comparator(a.expectedVersion, b.expectedVersion);
	}

	return 0;
};
