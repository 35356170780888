import { ManifestBasic } from "../../../api";
import { ManifestsListFilters } from "../../../types";

export const filterManifests = (
	manifests: ManifestBasic[],
	filters: ManifestsListFilters
): ManifestBasic[] => {
	const { searchByVersion } = filters;
	return manifests.filter((manifestComponent) => {
		return searchByVersion
			? manifestComponent.version.includes(searchByVersion)
			: true;
	});
};
