import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PersonIcon from "@mui/icons-material/Person";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import WifiFindIcon from "@mui/icons-material/WifiFind";
import {
	Divider,
	Drawer,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import React from "react";
import { useNavigate } from "react-router-dom";

import { DriftDetectionStatus, Environment } from "../../api";
import { useUser } from "../../hooks/useUser";
import { DATETIME_FORMAT, formatDate } from "../../utils";
import { DeleteEnvironmentDialog } from "./DeleteEnvironmentDialog";

export type EnvironmentCardDetailProps = {
	environment: Environment;
	filteredApplications: string[];
	manifestNames: string[];
	open: boolean;
	onClose: () => void;
	handleDelete: (id: string, comment?: string) => void;
};

export const EnvironmentCardDetail = ({
	environment,
	manifestNames,
	open,
	onClose,
	handleDelete,
}: EnvironmentCardDetailProps) => {
	const theme = useTheme();
	const isScreenSizeSx = useMediaQuery(theme.breakpoints.between("xs", "sm"));
	const anchor = isScreenSizeSx ? "bottom" : "right";
	const { palette } = useTheme();
	const navigate = useNavigate();
	const [openDeleteEnvironmentDialog, setOpenDeleteEnvironmentDialog] =
		React.useState(false);
	const { isAdminUser } = useUser();

	const handleConfirmDeleteEnvironment = (comment: string) => {
		handleDelete(environment._id, comment);
		setOpenDeleteEnvironmentDialog(false);
		onClose();
	};
	const handleCloseDeleteEnvironment = () => {
		setOpenDeleteEnvironmentDialog(false);
	};

	return (
		<Drawer
			anchor={anchor}
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					width: { xs: "100%", sm: "60%", md: "40%" },
					borderRadius: "10px 0 0 0",
					maxHeight: { xs: "500px", sm: "100%" },
				},
			}}
		>
			<Box sx={{ flexGrow: 1, padding: 3 }}>
				<div style={{ textAlign: "center" }}>
					<Typography
						sx={{
							fontSize: 25,
							fontWeight: "bold",
							display: "inline",
						}}
					>
						{environment.label}
					</Typography>
				</div>
				{environment.driftDetectionStatus !== DriftDetectionStatus.RUNNING && (
					<div
						style={{
							position: "absolute",
							right: "10px",
							top: "15px",
						}}
					>
						{isAdminUser && (
							<>
								<Tooltip title={"Edit"}>
									<IconButton
										color="primary"
										onClick={() =>
											navigate(`/environments/${environment._id}/edit`)
										}
									>
										<EditIcon fontSize={"medium"} />
									</IconButton>
								</Tooltip>
								<Tooltip title={"Delete"}>
									<IconButton
										color="primary"
										onClick={() => setOpenDeleteEnvironmentDialog(true)}
									>
										<DeleteIcon fontSize={"medium"} />
									</IconButton>
								</Tooltip>
							</>
						)}
					</div>
				)}
				<Divider sx={{ paddingTop: "10px" }}>Manifests</Divider>
				{manifestNames.length > 0 && (
					<List>
						{manifestNames.map((m) => (
							<ListItem key={`manifest-${m}`}>
								<ListItemIcon sx={{ minWidth: "30px" }}>
									<InsertDriveFileIcon
										sx={{
											color: "white",
											backgroundColor: palette.primary.main,
											borderRadius: "25%",
											fontSize: "25px",
										}}
									/>
								</ListItemIcon>
								<ListItemText
									primaryTypographyProps={{ style: { fontSize: "25px" } }}
									primary={m}
								/>
							</ListItem>
						))}
					</List>
				)}
				<Divider sx={{ paddingTop: "10px" }}>Environment Information's</Divider>
				<Grid container spacing={2} pt={2}>
					<Grid item xs={6}>
						Description:
					</Grid>
					<Grid item xs={6}>
						{environment.description}
					</Grid>
					<Grid item xs={6}>
						Owner:
					</Grid>
					<Grid item xs={6}>
						{environment.owner}
					</Grid>
					<Grid item xs={6}>
						Purpose:
					</Grid>
					<Grid item xs={6}>
						{environment.purpose}
					</Grid>
					<Grid item xs={6}>
						Template:
					</Grid>
					<Grid item xs={6}>
						{environment.template ?? "-"}
					</Grid>
				</Grid>
				<Divider sx={{ paddingTop: "10px" }}>Kubernetes</Divider>
				<Grid container spacing={2} pt={2}>
					<Grid item xs={6}>
						Namespaces:
					</Grid>
					<Grid item xs={6}>
						{environment.namespaces.join(", ")}
					</Grid>
					<Grid item xs={6}>
						Cluster:
					</Grid>
					<Grid item xs={6}>
						{environment.cluster}
					</Grid>
				</Grid>
				<Divider sx={{ paddingTop: "10px" }}>Audits</Divider>
				<Grid container spacing={2} pt={2}>
					<Grid item xs={12} sm={6}>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<CreateIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Created At"
								secondary={formatDate(
									new Date(environment.createdAt),
									DATETIME_FORMAT
								)}
							/>
						</ListItem>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<SyncAltIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Last Updated At"
								secondary={formatDate(
									new Date(environment.updatedAt),
									DATETIME_FORMAT
								)}
							/>
						</ListItem>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<PersonIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Author" secondary={environment.author} />
						</ListItem>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<HistoryIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Revision"
								secondary={environment.revision}
							/>
						</ListItem>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<WifiFindIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Last Drift Detection"
								secondary={
									environment.driftDetection?.lastScan
										? formatDate(
												new Date(environment.driftDetection?.lastScan),
												DATETIME_FORMAT
											)
										: "N/A"
								}
							/>
						</ListItem>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<WifiFindIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Drift Detection Status"
								secondary={
									environment.driftDetectionStatus ??
									DriftDetectionStatus.NOT_RUN
								}
							/>
						</ListItem>
					</Grid>
				</Grid>
			</Box>
			<DeleteEnvironmentDialog
				handleConfirm={handleConfirmDeleteEnvironment}
				handleClose={() => handleCloseDeleteEnvironment()}
				open={openDeleteEnvironmentDialog}
			></DeleteEnvironmentDialog>
		</Drawer>
	);
};
