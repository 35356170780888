import { Box, Grid, Paper, SelectChangeEvent, styled } from "@mui/material";
import React from "react";

import { Environment, Stages } from "../../api";
import { EnvironmentCard } from "./EnvironmentCard";
import { Filter } from "./Filter";

const Header = styled(Paper)(({ theme }) => ({
	backgroundColor: "#f4f5f7",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.primary,
}));
interface ItemProps {
	minHeight?: string; // Define minHeight as an optional string prop
}
const Item = styled(Paper)<ItemProps>(({ theme, minHeight }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
	minHeight: minHeight || "400px",
}));

export type EnvironmentViewProps = {
	filteredStages: string[];
	filteredApplications: string[];
	handleStageChange: (event: SelectChangeEvent<string[]>) => void;
	handleApplicationChange: (event: SelectChangeEvent<string[]>) => void;
	maxEnvironmentCount: number;
	environments: Environment[];
	applications: string[];
	handleDelete: (id: string, comment?: string) => void;
};
export const EnvironmentView = (props: EnvironmentViewProps) => {
	return (
		<Box>
			<Filter
				filteredApplications={props.filteredApplications}
				filteredStages={props.filteredStages}
				handleApplicationChange={props.handleApplicationChange}
				handleStageChange={props.handleStageChange}
				applications={props.applications}
			></Filter>
			<Box sx={{ overflowX: "scroll" }}>
				<Grid container spacing={2} p={2} wrap="nowrap">
					{(props.filteredStages.length === 0
						? Object.keys(Stages)
						: props.filteredStages
					).map((s) => (
						<Grid
							item
							xs={3}
							md={12}
							key={`header-${s}`}
							sx={{ minWidth: { xs: "101%", md: "400px" } }}
						>
							<Header>{s}</Header>
						</Grid>
					))}
				</Grid>
				<Grid container spacing={2} p={2} wrap="nowrap">
					{(props.filteredStages.length === 0
						? Object.keys(Stages)
						: props.filteredStages
					).map((s) => (
						<Grid
							item
							key={`grid-${s}`}
							xs={3}
							md={12}
							sx={{ minWidth: { xs: "101%", md: "400px" } }}
						>
							<Item
								key={`item-${s}`}
								style={{ backgroundColor: "#f4f5f7" }}
								minHeight={`${400 * props.maxEnvironmentCount}px`}
							>
								{props.environments
									.filter((env) => env.stage === s)
									.map((env) => (
										<EnvironmentCard
											key={`card-${env._id}`}
											environment={env}
											filteredApplications={props.filteredApplications}
											handleDelete={props.handleDelete}
										></EnvironmentCard>
									))}
							</Item>
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	);
};
