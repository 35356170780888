import React from "react";

import {
	CreateEnvironmentPage,
	EditEnvironmentPage,
	EnvironmentViewPage,
	ListManifestPage,
	ListVersionComponentPage,
	NotFoundPage,
} from "../pages";

interface Route {
	path: string;
	element: React.ReactNode;
	title: string;
	requiresAdminAccess: boolean;
}

export const navRoutes: Route[] = [
	{
		path: "/versions",
		element: <ListVersionComponentPage />,
		title: "Versions",
		requiresAdminAccess: false,
	},
	{
		path: "/environments",
		element: <EnvironmentViewPage />,
		title: "Environments",
		requiresAdminAccess: false,
	},
	{
		path: "/manifests",
		element: <ListManifestPage />,
		title: "Manifests",
		requiresAdminAccess: false,
	},
];

export const publicRoutes: Route[] = [
	{
		path: "/404",
		element: <NotFoundPage />,
		title: "Not Found",
		requiresAdminAccess: false,
	},
];

export const privateRoutes: Route[] = [
	{
		path: "/versions",
		element: <ListVersionComponentPage />,
		title: "Versions",
		requiresAdminAccess: false,
	},
	{
		path: "/environments",
		element: <EnvironmentViewPage />,
		title: "Environments",
		requiresAdminAccess: false,
	},
	{
		path: "/environments/create",
		element: <CreateEnvironmentPage />,
		title: "Create Environment",
		requiresAdminAccess: true,
	},
	{
		path: "/environments/:id/edit",
		element: <EditEnvironmentPage />,
		title: "Edit Environment",
		requiresAdminAccess: true,
	},
	{
		path: "/manifests",
		element: <ListManifestPage />,
		title: "Manifests",
		requiresAdminAccess: false,
	},
];
