export enum Types {
	"STRING" = "string",
	"INTEGER" = "integer",
	"BOOLEAN" = "boolean",
	"DATE" = "date",
	"DATETIME" = "datetime",
	"ARRAY" = "array",
	"OBJECT" = "object",
}

export enum Stages {
	"DEV" = "DEV",
	"SIT" = "SIT",
	"UAT" = "UAT",
	"DEMO" = "DEMO",
	"PPT" = "PPT",
	"PROD" = "PROD",
}

export enum DriftDetectionStatus {
	"NOT_RUN" = "NOT RUN",
	"RUNNING" = "RUNNING",
	"COMPLETED" = "COMPLETED",
}
