import { Grid } from "@mui/material";
import { Spinner } from "@unified-trials/arcane-ui-tool";
import React from "react";

import { Environment, EnvironmentComponentStatePayload } from "../../api";
import { useApi } from "../../hooks";
import { ManifestComponent } from "../../types";
import { ManifestComponentsTable } from "./list/ManifestComponentsTable";

export type EnvironmentComponentsProps = {
	environment: Environment;
	manifestComponents: ManifestComponent[];
	envComponents?: EnvironmentComponentStatePayload;
	handleDownload: (environmentId: string) => Promise<void>;
};

export const EnvironmentComponents = ({
	environment,
	manifestComponents,
	envComponents,
	handleDownload,
}: EnvironmentComponentsProps) => {
	const { isLoading } = useApi();

	return (
		<Grid container>
			<Grid item xs={12}>
				{isLoading && <Spinner fullscreen opacity={0.5} />}
				<ManifestComponentsTable
					manifestComponents={manifestComponents}
					lastDriftDetection={envComponents?.lastDriftDetection}
					handleDownload={() => handleDownload(environment._id)}
					hasManifest={!!envComponents?.manifests}
				/>
			</Grid>
		</Grid>
	);
};
