import { Button, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";

export type DeleteEnvironmentDialogProps = {
	open: boolean;
	handleClose: () => void;
	handleConfirm: (comment: string) => void;
};

export const DeleteEnvironmentDialog = ({
	open,
	handleClose,
	handleConfirm,
}: DeleteEnvironmentDialogProps) => {
	const [comment, setComment] = useState<string>("");
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<DialogTitle>
				{
					<>
						Are you sure you want to{" "}
						<Typography
							variant="inherit"
							component="span"
							style={{ color: "red", fontWeight: "bold" }}
						>
							permanently delete
						</Typography>{" "}
						this environment? This action cannot be undone.
					</>
				}
			</DialogTitle>
			<DialogContent>
				<DialogContentText p={1}>
					<TextField
						size="small"
						sx={{ width: "100%" }}
						label="Comment"
						defaultValue={""}
						multiline
						rows={4}
						value={comment}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setComment(event.target.value);
						}}
					/>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant={"outlined"}>
					Cancel
				</Button>
				<Button
					onClick={() => handleConfirm(comment)}
					autoFocus
					color={"error"}
					variant={"contained"}
				>
					Yes, delete it
				</Button>
			</DialogActions>
		</Dialog>
	);
};
