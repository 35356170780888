import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { Alert } from "@mui/material";
import { loginRequest, useAuth } from "@unified-trials/arcane-sso-react";
import { AppRoutes, Layout, Spinner } from "@unified-trials/arcane-ui-tool";
import React, { ReactNode } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { useUser } from "../hooks/useUser";
import { navRoutes, privateRoutes, publicRoutes } from "./routes";

const LoadingSpinner = () => <Spinner fullscreen />;

interface NoteLayoutProps {
	children: ReactNode;
}

const NoteLayout: React.FC<NoteLayoutProps> = ({ children }) => {
	return (
		<>
			<Alert
				variant="filled"
				severity="warning"
				sx={{
					justifyContent: "center",
					backgroundColor: "#ffcc6e",
				}}
			>
				<b>
					<i>
						Note: Tool in Development. For feedback:{" "}
						<a
							target="_blank"
							href="https://teams.microsoft.com/l/message/19:eba73776513b419283e92ba30a482d54@thread.tacv2/1712132010427?tenantId=ec8463ab-fb70-4b0b-afa8-acfab5e97353&groupId=04458742-3c16-4039-ae06-5856722327c4&parentMessageId=1712132010427&teamName=Boost%20Tribe&channelName=Squad%20-%20Arcane&createdTime=1712132010427"
						>
							Team Arcane
						</a>
					</i>
				</b>
			</Alert>
			{children}
		</>
	);
};

type PrivateRoutesProps = {
	applicationConfigString: string;
};
const PrivateRoutes = ({ applicationConfigString }: PrivateRoutesProps) => {
	const { instance, inProgress } = useMsal();
	const { logout, username } = useAuth();

	const activeAccount = instance.getActiveAccount();

	const authRequest = {
		...loginRequest,
		...(activeAccount && { account: activeAccount }),
		redirectUri: process.env.REACT_APP_REDIRECT_URL,
	};

	const handleLogout = (e: React.MouseEvent) => {
		e.preventDefault();
		logout();
	};

	if (inProgress === InteractionStatus.Startup) {
		return <Spinner fullscreen />;
	}

	const appRoutes = AppRoutes(applicationConfigString, true);

	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest}
			loadingComponent={LoadingSpinner}
		>
			<Layout
				handleLogout={handleLogout}
				navRoutes={navRoutes}
				appName={"Version Page"}
				isSidebar={true}
				platformName={"Platform Tools"}
				appRoutes={appRoutes}
				username={username}
			>
				<NoteLayout>
					<Outlet />
				</NoteLayout>
			</Layout>
		</MsalAuthenticationTemplate>
	);
};

type RouterProps = {
	applicationConfigString: string;
};
export const Router = ({ applicationConfigString }: RouterProps) => {
	const { isAdminUser } = useUser();

	return (
		<Routes>
			{publicRoutes.map((r) => (
				<Route key={r.path} path={r.path} element={r.element} />
			))}

			<Route
				element={
					<PrivateRoutes applicationConfigString={applicationConfigString} />
				}
			>
				{privateRoutes.map((r) => {
					return (
						(!r.requiresAdminAccess || isAdminUser) && (
							<Route key={r.path} path={r.path} element={r.element} />
						)
					);
				})}
			</Route>

			<Route path="*" element={<Navigate to="/versions" replace />} />
		</Routes>
	);
};
