import { zodResolver } from "@hookform/resolvers/zod";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
	Autocomplete,
	Button,
	Chip,
	Grid,
	MenuItem,
	Paper,
	Stack,
	TextField,
	Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import { Spinner } from "@unified-trials/arcane-ui-tool";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { GetEnvironment, ManifestBasic, Stages } from "../../api";
import { EditEnvironmentPayload } from "../../api/interfaces/environment/patch";
import { useApi } from "../../hooks";
import { showNotification } from "../../utils";
import { HiddenComponentsTransferList } from "./HiddenComponentsTransferList";

const EnvironmentSchema = z.object({
	label: z.string().min(1, { message: "Label is required" }),
	name: z.string().min(1, { message: "Name is required" }),
	cluster: z.string().min(1, { message: "Cluster is required" }),
	namespaces: z
		.array(z.string())
		.min(1, { message: "Namespaces are required" }),
	description: z.string().min(1, { message: "Description is required" }),
	owner: z.string().min(1, { message: "Owner is required" }),
	purpose: z.string(),
	template: z.string(),
	manifests: z.string().array(),
	stage: z.nativeEnum(Stages),
});

type FormValues = z.infer<typeof EnvironmentSchema>;

export type EditEnvironmentFormProps = {
	environmentId: string;
	environment: GetEnvironment;
	manifests: ManifestBasic[];
};

export const EditEnvironmentForm = (props: EditEnvironmentFormProps) => {
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const [editEnvironmentPayload, setEditEnvironmentPayload] =
		React.useState<EditEnvironmentPayload>({
			label: props.environment.label,
			name: props.environment.name,
			cluster: props.environment.cluster,
			description: props.environment.description,
			manifests: props.environment.manifests,
			namespaces: props.environment.namespaces,
			owner: props.environment.owner,
			purpose: props.environment.purpose,
			stage: props.environment.stage,
			template: props.environment.template,
			comment: "",
			hiddenComponents: props.environment.hiddenComponents ?? [],
		});

	const [hiddenComponents, setHiddenComponents] = React.useState<string[]>([]);
	const [components, setComponents] = React.useState<string[]>([]);

	const { isLoading, editEnvironment } = useApi();
	const {
		handleSubmit,
		register,
		control,
		setValue,
		watch,
		trigger,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "all",
		resolver: zodResolver(EnvironmentSchema),
	});

	const handleClickSave = async () => {
		const { error } = await editEnvironment(
			props.environmentId,
			editEnvironmentPayload
		);
		setEditEnvironmentPayload({
			...editEnvironmentPayload,
			comment: "",
		});
		setOpen(false);
		if (!error) {
			showNotification("success", "The environment was successfully edited");
			navigate("/environments");
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		setValue("name", props.environment.name);
		setValue("label", props.environment.label);
		setValue("cluster", props.environment.cluster);
		setValue("namespaces", props.environment.namespaces);
		setValue("description", props.environment.description);
		setValue("owner", props.environment.owner);
		setValue("purpose", props.environment.purpose ?? "");
		setValue("template", props.environment.template ?? "");

		const driftDetectionComponents =
			props.environment.driftDetection?.components
				.map((item) => item.name)
				.concat(
					props.environment.driftDetection?.unexpectedComponents.map(
						(item) => item.name
					)
				) ?? [];
		const environmentHiddenComponents =
			props.environment.hiddenComponents ?? [];

		const visibleComponents = driftDetectionComponents.filter(
			(item) => !environmentHiddenComponents.includes(item)
		);
		const distinctVisibleComponents = [...new Set([...visibleComponents])];

		setHiddenComponents(environmentHiddenComponents);
		setComponents(distinctVisibleComponents);
	}, []);

	const handleFormSubmit: SubmitHandler<FormValues> = async (data) => {
		const requestData: EditEnvironmentPayload = {
			label: data.label,
			name: data.name,
			cluster: data.cluster,
			namespaces: data.namespaces,
			description: data.description,
			owner: data.owner,
			purpose: data.purpose,
			template: data.template,
			manifests: data.manifests,
			stage: data.stage,
			hiddenComponents,
		};

		setEditEnvironmentPayload(requestData);
		setOpen(true);
	};

	if (isLoading) <Spinner fullscreen opacity={0.5} />;

	return (
		<form onSubmit={handleSubmit(handleFormSubmit)}>
			<Paper sx={{ p: 2 }} elevation={0}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("name")}
							size="small"
							sx={{ width: "100%" }}
							label="Name*"
							error={!!errors.name}
							helperText={errors.name?.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<Tooltip title="Name of the environment">
											<QuestionMarkIcon />
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("label")}
							size="small"
							sx={{ width: "100%" }}
							label="Label*"
							error={!!errors.label}
							helperText={errors.label?.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<Tooltip title="Short label of the environment, for example 'DEV01' or 'SIT'">
											<QuestionMarkIcon />
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("cluster")}
							size="small"
							sx={{ width: "100%" }}
							label="Cluster*"
							error={!!errors.cluster}
							helperText={errors.cluster?.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<Tooltip title="The name of the Kubernetes cluster that the instance is deployed to">
											<QuestionMarkIcon />
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Autocomplete
							disableClearable
							options={[]}
							value={watch("namespaces") ?? []}
							size="small"
							sx={{ width: "100%" }}
							freeSolo
							multiple
							onChange={(event, newValue) => {
								setValue("namespaces", newValue);
								trigger("namespaces");
							}}
							onBlur={() => trigger("namespaces")}
							renderTags={(value) =>
								value.map((option, index) => (
									<Chip
										key={index}
										label={option}
										sx={{ height: "28px", marginRight: "2px" }}
										onDelete={() => {
											const newNamespaces = value.filter((x) => x !== option);
											setValue("namespaces", newNamespaces);
										}}
									/>
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Namespaces*"
									placeholder="Type and press enter"
									sx={{ width: "100%", padding: 0 }}
									error={!!errors.namespaces}
									helperText={errors.namespaces?.message}
									InputProps={{
										...params.InputProps,
										style: {
											// Override MUI inline styles to match our design
											paddingTop: 6,
											paddingBottom: 6,
											paddingLeft: 6,
											paddingRight: 14,
										},
										endAdornment: (
											<InputAdornment position="start">
												<Tooltip title="The name of the Kubernetes namespaces that the applications are deployed to">
													<QuestionMarkIcon />
												</Tooltip>
											</InputAdornment>
										),
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("description")}
							size="small"
							sx={{ width: "100%" }}
							label="Description*"
							error={!!errors.description}
							helperText={errors.description?.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<Tooltip title="Label of the environment, describing its purpose, for example 'SIT' or 'Penetration Testing'">
											<QuestionMarkIcon />
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("owner")}
							size="small"
							sx={{ width: "100%" }}
							label="Owner*"
							error={!!errors.owner}
							helperText={errors.owner?.message}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<Tooltip title="Reference to the group that owns the environment">
											<QuestionMarkIcon />
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("purpose")}
							size="small"
							sx={{ width: "100%" }}
							label="Purpose"
							defaultValue={""}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<Tooltip title="The purpose of the environment instance, for example 'Continuous Integration', 'SIT Testing' or 'Production'">
											<QuestionMarkIcon />
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("template")}
							size="small"
							sx={{ width: "100%" }}
							label="Template"
							defaultValue={""}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<Tooltip title="The name of the infrastructure definition template that was used to deploy the environment instance">
											<QuestionMarkIcon />
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Controller
							control={control}
							name="manifests"
							defaultValue={props.environment.manifests}
							render={({ field }) => {
								const { onChange } = field;
								return (
									<Autocomplete
										multiple
										options={props.manifests}
										defaultValue={props.manifests.filter((x) =>
											props.environment.manifests.includes(x.id)
										)}
										disableCloseOnSelect
										getOptionLabel={(option) => option.version}
										isOptionEqualToValue={(option, v) => option.id === v.id}
										filterSelectedOptions
										size={"small"}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Manifests"
												placeholder="Manifests"
												size="small"
												sx={{ width: "100%" }}
											/>
										)}
										onChange={(event, selectedOptions) => {
											onChange(selectedOptions.map((x) => x.id));
										}}
									/>
								);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register("stage")}
							defaultValue={props.environment.stage}
							size="small"
							sx={{ width: "100%" }}
							label="Stage"
							error={!!errors.stage}
							helperText={errors.stage?.message}
							select
						>
							{Object.values(Stages).map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<HiddenComponentsTransferList
							setHiddenComponents={setHiddenComponents}
							hiddenComponents={hiddenComponents}
							components={components}
							setComponents={setComponents}
						/>
					</Grid>
				</Grid>
			</Paper>
			<Stack direction="row" spacing={2} my={1} justifyContent={"center"}>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					sx={{ color: "white " }}
				>
					Submit
				</Button>
			</Stack>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle>
					{"Add a comment about your environment changes"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText p={1}>
						<TextField
							size="small"
							sx={{ width: "100%" }}
							label="Comment"
							defaultValue={""}
							multiline
							rows={4}
							value={editEnvironmentPayload.comment}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setEditEnvironmentPayload({
									...editEnvironmentPayload,
									comment: event.target.value,
								});
							}}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClickSave} autoFocus>
						Continue
					</Button>
				</DialogActions>
			</Dialog>
		</form>
	);
};
