import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
	Divider,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import * as React from "react";

function not(a: readonly string[], b: readonly string[]) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

export type HiddenComponentsTransferListProps = {
	hiddenComponents: string[];
	setHiddenComponents: React.Dispatch<React.SetStateAction<string[]>>;
	components: string[];
	setComponents: React.Dispatch<React.SetStateAction<string[]>>;
};

export const HiddenComponentsTransferList = ({
	hiddenComponents,
	components,
	setHiddenComponents,
	setComponents,
}: HiddenComponentsTransferListProps) => {
	const theme = useTheme();
	const isScreenSizeSx = useMediaQuery(theme.breakpoints.between("xs", "sm"));
	const direction = isScreenSizeSx ? "column" : "row";
	const [checked, setChecked] = React.useState<readonly string[]>([]);

	const leftChecked = intersection(checked, components);
	const rightChecked = intersection(checked, hiddenComponents);

	const handleToggle = (value: string) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		setHiddenComponents(hiddenComponents.concat(components));
		setComponents([]);
	};

	const handleCheckedRight = () => {
		setHiddenComponents(hiddenComponents.concat(leftChecked));
		setComponents(not(components, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setComponents(components.concat(rightChecked));
		setHiddenComponents(not(hiddenComponents, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		setComponents(components.concat(hiddenComponents));
		setHiddenComponents([]);
	};

	const customList = (
		items: readonly string[],
		title: string,
		tooltip: string
	) => (
		<Paper
			sx={{
				width: { xs: "100%", sm: "45%" },
				height: 230,
				overflow: "auto",
			}}
		>
			<Typography m={2} sx={{ color: "#65748b" }}>
				{title}{" "}
				<InputAdornment position="start" sx={{ float: "right", marginTop: 1 }}>
					<Tooltip title={tooltip}>
						<QuestionMarkIcon />
					</Tooltip>
				</InputAdornment>
			</Typography>
			<Divider />
			<List dense component="div" role="list">
				{items.map((value: string) => {
					const labelId = `transfer-list-item-${value}-label`;

					return (
						<ListItemButton
							key={value}
							role="listitem"
							onClick={handleToggle(value)}
						>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										"aria-labelledby": labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={value} />
						</ListItemButton>
					);
				})}
			</List>
		</Paper>
	);

	return (
		<Grid container direction={direction} alignItems="center">
			{customList(
				components,
				"Visible Components",
				"The component list will be shown in The Versions List"
			)}
			<Grid
				container
				direction="column"
				alignItems="center"
				sx={{
					transform: { xs: "rotate(90deg)", sm: "none" },
					width: { xs: "100%", sm: "10%" },
				}}
			>
				<Button
					sx={{ mb: { xs: 0, sm: 0.5 }, mt: { xs: 0, sm: 3 } }}
					variant="outlined"
					size="small"
					onClick={handleAllRight}
					disabled={components.length === 0}
					aria-label="move all right"
				>
					≫
				</Button>
				<Button
					sx={{ my: 0.5 }}
					variant="outlined"
					size="small"
					onClick={handleCheckedRight}
					disabled={leftChecked.length === 0}
					aria-label="move selected right"
				>
					&gt;
				</Button>
				<Button
					sx={{ my: 0.5 }}
					variant="outlined"
					size="small"
					onClick={handleCheckedLeft}
					disabled={rightChecked.length === 0}
					aria-label="move selected left"
				>
					&lt;
				</Button>
				<Button
					sx={{ my: 0.5 }}
					variant="outlined"
					size="small"
					onClick={handleAllLeft}
					disabled={hiddenComponents.length === 0}
					aria-label="move all left"
				>
					≪
				</Button>
			</Grid>
			{customList(
				hiddenComponents,
				"Hidden Components",
				"The component list will be hidden in The Versions List"
			)}
		</Grid>
	);
};
