import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

import { SortableHeaders } from "../../../constants";
import { SortingOrder } from "../../../types";

interface HeadCell {
	id: string;
	label: string;
	sortable: boolean;
	sortKey?: SortableHeaders;
	mobileDisplay: string;
}

const headCells: HeadCell[] = [
	{
		id: "app",
		label: "App",
		sortable: true,
		sortKey: SortableHeaders.APP,
		mobileDisplay: "none",
	},
	{
		id: "manifestVersion",
		label: "Manifest",
		sortable: true,
		sortKey: SortableHeaders.MANIFESTVERSION,
		mobileDisplay: "table-cell",
	},
	{
		id: "name",
		label: "Component",
		sortable: true,
		sortKey: SortableHeaders.NAME,
		mobileDisplay: "table-cell",
	},
	{
		id: "version",
		label: "Version",
		sortable: true,
		sortKey: SortableHeaders.VERSION,
		mobileDisplay: "table-cell",
	},
	{
		id: "data",
		label: "Data",
		sortable: false,
		mobileDisplay: "none",
	},
];

export interface ManifestComponentsTableHeaderProps {
	order: SortingOrder;
	orderBy: string;
	createSortHandler: (property: SortableHeaders) => void;
}

export const ManifestComponentsTableHeader = ({
	order,
	orderBy,
	createSortHandler,
}: ManifestComponentsTableHeaderProps) => {
	return (
		<TableHead>
			<TableRow>
				{headCells.map(({ id, label, sortable, sortKey, mobileDisplay }) => (
					<TableCell
						key={id}
						sx={{
							background: "#e3e3e3",
							display: { xs: mobileDisplay, sm: "table-cell" },
						}}
					>
						{sortable && sortKey ? (
							<TableSortLabel
								active={orderBy === id}
								direction={orderBy === id ? order : "asc"}
								onClick={() => createSortHandler(sortKey)}
							>
								{label}
							</TableSortLabel>
						) : (
							label
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};
