import { zodResolver } from "@hookform/resolvers/zod";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
	Autocomplete,
	Button,
	Chip,
	Container,
	Grid,
	MenuItem,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { BackButton, Spinner } from "@unified-trials/arcane-ui-tool";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { ManifestBasic, Stages } from "../../api";
import { CreateEnvironment } from "../../api/interfaces/environment/put";
import { useApi } from "../../hooks";
import { showNotification } from "../../utils";

const EnvironmentSchema = z.object({
	label: z.string().min(1, { message: "Label is required" }),
	name: z.string().min(1, { message: "Name is required" }),
	cluster: z.string().min(1, { message: "Cluster is required" }),
	namespaces: z
		.array(z.string())
		.min(1, { message: "Namespaces are required" }),
	description: z.string().min(1, { message: "Description is required" }),
	owner: z.string().min(1, { message: "Owner is required" }),
	purpose: z.string(),
	template: z.string(),
	manifests: z.string().array(),
	stage: z.nativeEnum(Stages),
});

type FormValues = z.infer<typeof EnvironmentSchema>;

export const CreateEnvironmentPage: React.FC = () => {
	const {
		handleSubmit,
		register,
		control,
		watch,
		setValue,
		trigger,
		formState: { errors },
	} = useForm<FormValues>({
		mode: "all",
		resolver: zodResolver(EnvironmentSchema),
		defaultValues: {
			label: "",
			name: "",
			cluster: "",
			namespaces: [],
			description: "",
			owner: "",
			purpose: "",
			template: "",
			manifests: [],
			stage: Stages.DEV,
		},
	});

	const { createEnvironment } = useApi();
	const navigate = useNavigate();
	const { isLoading, listManifests } = useApi();
	const [manifests, setManifests] = useState<ManifestBasic[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			const manifestsResult = await listManifests();
			if (manifestsResult.data?.length && manifestsResult.data.length > 0) {
				setManifests(manifestsResult.data);
			}
		};

		fetchData();
	}, []);

	const handleFormSubmit: SubmitHandler<FormValues> = async (data) => {
		const requestData: CreateEnvironment = {
			label: data.label,
			name: data.name,
			cluster: data.cluster,
			namespaces: data.namespaces,
			description: data.description,
			owner: data.owner,
			purpose: data.purpose,
			template: data.template,
			manifests: data.manifests ?? [],
			stage: data.stage,
		};
		const { error } = await createEnvironment(requestData);
		if (!error) {
			showNotification("success", "Successfully created new environment");
			navigate("/environments");
		}
	};

	return (
		<Container sx={{ marginBottom: 2, width: "100%" }}>
			{isLoading && <Spinner fullscreen opacity={0.5} />}
			<Stack direction="row" spacing={2} my={1} alignItems="center">
				<BackButton href="/environments" />
				<Typography variant="h4" my={2}>
					Create Environment
				</Typography>
			</Stack>
			<form onSubmit={handleSubmit(handleFormSubmit)}>
				<Paper sx={{ p: 2 }} elevation={0}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								{...register("name")}
								size="small"
								sx={{ width: "100%" }}
								label="Name*"
								error={!!errors.name}
								helperText={errors.name?.message}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<Tooltip title="Name of the environment">
												<QuestionMarkIcon />
											</Tooltip>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								{...register("label")}
								size="small"
								sx={{ width: "100%" }}
								label="Label*"
								error={!!errors.label}
								helperText={errors.label?.message}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<Tooltip title="Short label of the environment, for example 'DEV01' or 'SIT'">
												<QuestionMarkIcon />
											</Tooltip>
										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField
								{...register("cluster")}
								size="small"
								sx={{ width: "100%" }}
								label="Cluster*"
								error={!!errors.cluster}
								helperText={errors.cluster?.message}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<Tooltip title="The name of the Kubernetes cluster that the instance is deployed to">
												<QuestionMarkIcon />
											</Tooltip>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								disableClearable
								options={[]}
								value={watch("namespaces")}
								size="small"
								sx={{ width: "100%" }}
								freeSolo
								multiple
								onChange={(event, newValue) => {
									setValue("namespaces", newValue);
									trigger("namespaces");
								}}
								onBlur={() => trigger("namespaces")}
								renderTags={(value) =>
									value.map((option, index) => (
										<Chip
											key={index}
											label={option}
											sx={{ height: "28px", marginRight: "2px" }}
											onDelete={() => {
												const newNamespaces = value.filter((x) => x !== option);
												setValue("namespaces", newNamespaces);
											}}
										/>
									))
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Namespaces*"
										placeholder="Type and press enter"
										sx={{ width: "100%", padding: 0 }}
										error={!!errors.namespaces}
										helperText={errors.namespaces?.message}
										InputProps={{
											...params.InputProps,
											style: {
												// Override MUI inline styles to match our design
												paddingTop: 6,
												paddingBottom: 6,
												paddingLeft: 6,
												paddingRight: 14,
											},
											endAdornment: (
												<InputAdornment position="start">
													<Tooltip title="The name of the Kubernetes namespaces that the applications are deployed to">
														<QuestionMarkIcon />
													</Tooltip>
												</InputAdornment>
											),
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								{...register("description")}
								size="small"
								sx={{ width: "100%" }}
								label="Description*"
								error={!!errors.description}
								helperText={errors.description?.message}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<Tooltip title="Label of the environment, describing its purpose, for example 'SIT' or 'Penetration Testing'">
												<QuestionMarkIcon />
											</Tooltip>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								{...register("owner")}
								size="small"
								sx={{ width: "100%" }}
								label="Owner*"
								error={!!errors.owner}
								helperText={errors.owner?.message}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<Tooltip title="Reference to the group that owns the environment">
												<QuestionMarkIcon />
											</Tooltip>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								{...register("purpose")}
								size="small"
								sx={{ width: "100%" }}
								label="Purpose"
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<Tooltip title="The purpose of the environment instance, for example 'Continuous Integration', 'SIT Testing' or 'Production'">
												<QuestionMarkIcon />
											</Tooltip>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								{...register("template")}
								size="small"
								sx={{ width: "100%" }}
								label="Template"
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<Tooltip title="The name of the infrastructure definition template that was used to deploy the environment instance">
												<QuestionMarkIcon />
											</Tooltip>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								control={control}
								name="manifests"
								render={({ field }) => {
									const { onChange } = field;
									return (
										<Autocomplete
											multiple
											options={manifests}
											getOptionLabel={(option) => option.version}
											isOptionEqualToValue={(option, v) => option.id === v.id}
											filterSelectedOptions
											size={"small"}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Manifests"
													placeholder="Manifests"
													size="small"
													sx={{ width: "100%" }}
												/>
											)}
											onChange={(event, selectedOptions) => {
												onChange(selectedOptions.map((x) => x.id));
											}}
										/>
									);
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								{...register("stage")}
								size="small"
								sx={{ width: "100%" }}
								label="Stage"
								error={!!errors.stage}
								helperText={errors.stage?.message}
								defaultValue={Stages.DEV}
								select
							>
								{Object.values(Stages).map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
				</Paper>

				<Stack direction="row" spacing={2} my={1} justifyContent={"center"}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						sx={{ color: "white " }}
					>
						Submit
					</Button>
				</Stack>
			</form>
		</Container>
	);
};
