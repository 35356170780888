import InsertLinkIcon from "@mui/icons-material/InsertLink";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
	TableCell,
	TableRow,
	Tooltip,
	TooltipProps,
	styled,
	tooltipClasses,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { ManifestComponent } from "../../../types";

const generateComponentVersionComparisonText = (
	expectedVersion: string,
	actualVersion: string | null
) => {
	if (!actualVersion) {
		return <s>{expectedVersion}</s>;
	}
	if (actualVersion && actualVersion !== expectedVersion) {
		return (
			<>
				<s>{expectedVersion}</s> {actualVersion}
			</>
		);
	}
	return expectedVersion;
};

const NoTextWrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: "none",
		fontSize: 14,
	},
});

export type ManifestComponentsTableRowProps = {
	manifestComponent: ManifestComponent;
	lastDriftDetection?: string | null;
	setOpenComponentJsonView: React.Dispatch<React.SetStateAction<boolean>>;
	setComponentJsonViewData: React.Dispatch<React.SetStateAction<unknown>>;
	setJsonViewTitle: React.Dispatch<React.SetStateAction<string>>;
};

export const ManifestComponentsTableRow = ({
	manifestComponent,
	lastDriftDetection,
	setOpenComponentJsonView,
	setComponentJsonViewData,
	setJsonViewTitle,
}: ManifestComponentsTableRowProps) => {
	return (
		<TableRow
			hover
			sx={{
				background: "inherited",
				...(manifestComponent.isUnexpectedComponent && {
					background: "#f7e2ba",
				}),
			}}
		>
			<TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>
				{manifestComponent.appName}
			</TableCell>
			<TableCell>{manifestComponent.manifestVersion}</TableCell>
			<TableCell>
				{manifestComponent.name}
				{manifestComponent.isUnexpectedComponent ? "*" : ""}
			</TableCell>
			<TableCell>
				{lastDriftDetection && !manifestComponent.isUnexpectedComponent
					? generateComponentVersionComparisonText(
							manifestComponent.expectedVersion,
							manifestComponent.actualVersion
						)
					: manifestComponent.expectedVersion}
				{lastDriftDetection &&
					manifestComponent.actualVersion &&
					manifestComponent.actualVersion !==
						manifestComponent.expectedVersion &&
					!manifestComponent.isUnexpectedComponent && (
						<NoTextWrapTooltip
							title={`Component version differs from manifest. Running version: ${manifestComponent.actualVersion}`}
						>
							<IconButton>
								<WarningAmberIcon color={"warning"} />
							</IconButton>
						</NoTextWrapTooltip>
					)}
				{lastDriftDetection &&
					!manifestComponent.actualVersion &&
					!manifestComponent.isUnexpectedComponent && (
						<NoTextWrapTooltip
							title={`${manifestComponent.name} is not running in the environment`}
						>
							<IconButton>
								<PriorityHighIcon color={"error"} />
							</IconButton>
						</NoTextWrapTooltip>
					)}
			</TableCell>
			<TableCell
				align="center"
				sx={{ display: { xs: "none", sm: "table-cell" } }}
			>
				{!!manifestComponent.data && (
					<IconButton
						data-testid={`data-${manifestComponent.name}`}
						onClick={(e) => {
							setOpenComponentJsonView(true);
							setComponentJsonViewData(manifestComponent.data);
							setJsonViewTitle(manifestComponent.name);
							e.preventDefault();
						}}
					>
						<InsertLinkIcon color="primary" />
					</IconButton>
				)}
			</TableCell>
		</TableRow>
	);
};
