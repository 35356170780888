import { Alert } from "@mui/material";
import toast from "react-hot-toast";

type NotificationTypes = "success" | "info" | "warning" | "error";

export const showNotification = (
	type: NotificationTypes,
	message: string,
	duration?: number
) => {
	toast.custom(
		(t) => (
			<Alert
				severity={type}
				variant="filled"
				onClose={() => toast.dismiss(t.id)}
				style={{
					opacity: t.visible ? 1 : 0,
					transition: "opacity 100ms ease-in-out",
				}}
			>
				{message}
			</Alert>
		),
		{
			duration: duration || 4000,
		}
	);
};
