import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const formatDate = (date: Date, format: string) =>
	dayjs(date).format(format);

export const formatDateUTC = (date: Date, format: string) =>
	dayjs.utc(date).format(format);
