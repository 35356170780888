import axios from "axios";

import { showNotification } from "./showNotification";

export const displayError = (error: unknown) => {
	if (axios.isAxiosError(error)) {
		showNotification("error", error.response?.data?.message || error.message);
		return;
	}
	if (error instanceof Error) {
		showNotification("error", error.message);
		return;
	}
	showNotification("error", "Unknown error");
};
