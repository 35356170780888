import {
	Box,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TablePagination,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Dialog, EmptyTablePlaceholder } from "@unified-trials/arcane-ui-tool";
import { useCallback, useEffect, useMemo, useState } from "react";
import JsonView from "react18-json-view";

import { SortableHeaders } from "../../../constants";
import {
	ManifestComponent,
	ManifestComponentListFilters,
	SortingOrder,
} from "../../../types";
import { getComparator } from "../../../utils";
import { ManifestComponentsListFilter } from "./ManifestComponentsListFilter";
import { ManifestComponentsTableHeader } from "./ManifestComponentsTableHeader";
import { ManifestComponentsTableRow } from "./ManifestComponentsTableRow";
import {
	compareComponents,
	filterManifestComponents,
} from "./manifestComponentsListUtils";

export type ManifestComponentsTableProps = {
	manifestComponents: ManifestComponent[];
	lastDriftDetection?: string | null;
	handleDownload: () => Promise<void>;
	hasManifest: boolean;
};

export const ManifestComponentsTable = ({
	manifestComponents,
	lastDriftDetection,
	handleDownload,
	hasManifest,
}: ManifestComponentsTableProps) => {
	const theme = useTheme();
	const isScreenSizeSx = useMediaQuery(theme.breakpoints.between("xs", "sm"));
	const [openComponentJsonView, setOpenComponentJsonView] = useState(false);
	const [componentJsonViewData, setComponentJsonViewData] = useState<unknown>();
	const [jsonViewTitle, setJsonViewTitle] = useState<string>("");

	const [order, setOrder] = useState<SortingOrder>("desc");
	const [orderBy, setOrderBy] = useState<SortableHeaders>(
		isScreenSizeSx ? SortableHeaders.MANIFESTVERSION : SortableHeaders.APP
	);
	const [page, setPage] = useState(0);
	const [filters, setFilters] = useState<ManifestComponentListFilters>({
		searchValue: "",
	});
	const [rowsPerPage, setRowsPerPage] = useState(20);

	useEffect(() => {
		if (manifestComponents.length <= rowsPerPage) {
			setPage(0);
		}
	}, [manifestComponents]);

	const handleChangePage = async (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const createSortHandler = (property: SortableHeaders) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const comparator = useCallback(getComparator(order), [order]);

	const visibleRows = useMemo(
		() =>
			filterManifestComponents(manifestComponents, filters)
				.sort((a, b) => compareComponents(a, b, orderBy, comparator))
				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		[manifestComponents, filters, order, orderBy, page, rowsPerPage]
	);

	return (
		<Paper>
			<ManifestComponentsListFilter
				onFiltersChange={setFilters}
				handleDownload={handleDownload}
				hasManifest={hasManifest}
			/>
			<TableContainer>
				<Table stickyHeader>
					<ManifestComponentsTableHeader
						order={order}
						orderBy={orderBy}
						createSortHandler={createSortHandler}
					/>
					<TableBody>
						{visibleRows.length === 0 && <EmptyTablePlaceholder colSpan={5} />}
						{visibleRows.map((c, index) => (
							<ManifestComponentsTableRow
								key={`row-${c.appName}-${c.manifestVersion}-${c.name}-${index}`}
								manifestComponent={c}
								lastDriftDetection={lastDriftDetection}
								setComponentJsonViewData={setComponentJsonViewData}
								setJsonViewTitle={setJsonViewTitle}
								setOpenComponentJsonView={setOpenComponentJsonView}
							/>
						))}
					</TableBody>
				</Table>
				{openComponentJsonView && (
					<Dialog
						open={openComponentJsonView}
						setOpen={setOpenComponentJsonView}
						title={jsonViewTitle}
						minWidth={300}
					>
						<JsonView src={componentJsonViewData as object} />
					</Dialog>
				)}
			</TableContainer>
			<TablePagination
				count={filterManifestComponents(manifestComponents, filters).length}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[20, 40, { value: -1, label: "All" }]}
				onRowsPerPageChange={handleChangeRowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				component="div"
				sx={{
					background: "#e3e3e3",
					width: "100%",
					fontSize: "0.65rem",
					"& .MuiTablePagination-displayedRows": {
						fontSize: { xs: "0.65rem", sm: "0.87rem" },
					},
					"& .MuiTablePagination-input": {
						fontSize: { xs: "0.65rem", sm: "0.87rem" },
					},
					"& .MuiTablePagination-selectLabel": {
						fontSize: { xs: "0.65rem", sm: "0.87rem" },
					},
				}}
			/>
			<Box
				m={1}
				sx={{
					fontSize: "0.8em",
				}}
			>
				* These components are missing in the manifest but running in the
				environment
			</Box>
			<Box
				sx={{
					m: 1,
					display: "inline-block",
					fontSize: "0.8em",
				}}
			>
				<br />
				Page last updated: {new Date().toLocaleString()}
			</Box>
		</Paper>
	);
};
