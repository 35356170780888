import { Container, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import {
	Environment,
	EnvironmentComponentState,
	EnvironmentComponentStatePayload,
	UnexpectedComponent,
} from "../../api";
import { EnvironmentComponents, EnvironmentSelector } from "../../components";
import { useApi } from "../../hooks";
import { ManifestComponent } from "../../types";
import { showNotification } from "../../utils";

export const ListVersionComponentPage = () => {
	const { breakpoints } = useTheme();
	const isSmallScreen = useMediaQuery(breakpoints.down("sm"));

	const { getEnvComponentsByService, getManifestDocument } = useApi();

	const [environment, setEnvironment] = useState<Environment>();
	const [filteredApplications, setFilteredApplications] = useState<string[]>(
		[]
	);

	const [envComponents, setEnvComponents] =
		useState<EnvironmentComponentStatePayload>();
	const [manifestComponents, setManifestComponents] = useState<
		ManifestComponent[]
	>([]);

	const fetchData = async () => {
		if (filteredApplications.length === 0) {
			setEnvComponents(undefined);
			setManifestComponents([]);
		}

		if (filteredApplications.length > 0 && environment) {
			const envComponentsResult = await getEnvComponentsByService(
				environment._id,
				filteredApplications
			);
			if (envComponentsResult.data) {
				setEnvComponents(envComponentsResult.data);
				setManifestComponents([
					...envComponentsResult.data.components.map(
						(component: EnvironmentComponentState) => ({
							...component,
							isUnexpectedComponent: false,
							manifestVersion: component.manifestVersion,
							appName: component.appName,
						})
					),
					...envComponentsResult.data.unexpectedComponents.map(
						(unexpectedComponent: UnexpectedComponent) => ({
							name: unexpectedComponent.name,
							expectedVersion: unexpectedComponent.version,
							actualVersion: "",
							isUnexpectedComponent: true,
							manifestVersion: "",
							appName: "",
						})
					),
				]);
			} else {
				setEnvComponents(undefined);
				setManifestComponents([]);
			}
			window.scrollTo(0, 0);
		}
	};

	useEffect(() => {
		fetchData();
	}, [filteredApplications, environment]);

	const handleDownload = async () => {
		if (!environment) {
			showNotification("error", `No environment selected`);
			return;
		}
		const versionDocumentResult = await getManifestDocument(
			environment._id,
			filteredApplications
		);
		if (versionDocumentResult.data) {
			const downloadLink = document.createElement("a");
			downloadLink.href = versionDocumentResult.data;
			downloadLink.download = `${environment.label}-${filteredApplications.join("-")}-${Math.floor(Date.now() / 1000)}.pdf`;
			downloadLink.click();
		} else showNotification("error", `PDF is not found`);
	};

	return (
		<Container sx={{ ...(isSmallScreen && { p: 0 }) }}>
			<EnvironmentSelector
				setEnvironment={setEnvironment}
				filteredApplications={filteredApplications}
				setFilteredApplications={setFilteredApplications}
				envComponents={envComponents}
			></EnvironmentSelector>
			{environment && (
				<EnvironmentComponents
					environment={environment}
					manifestComponents={manifestComponents}
					envComponents={envComponents}
					handleDownload={handleDownload}
				/>
			)}
		</Container>
	);
};
