import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import {
	Box,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";

import { ManifestComponentListFilters } from "../../../types";

export interface ManifestComponentsListFilterProps {
	onFiltersChange: (filters: ManifestComponentListFilters) => void;
	handleDownload: () => Promise<void>;
	hasManifest: boolean;
}

export const ManifestComponentsListFilter: React.FC<
	ManifestComponentsListFilterProps
> = ({ onFiltersChange, handleDownload, hasManifest }) => {
	const [searchValue, setSearchValue] = useState<string>("");

	const updateFilters = (newFilters: Partial<ManifestComponentListFilters>) => {
		const updatedFilters: ManifestComponentListFilters = {
			searchValue,
			...newFilters,
		};
		onFiltersChange(updatedFilters);
	};

	const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setSearchValue(newValue);

		updateFilters({ searchValue: newValue });
	};

	const handleSearchValueClear = () => {
		setSearchValue("");
		updateFilters({ searchValue: "" });
	};

	return (
		<Grid container sx={{ p: { xs: 1, sm: 1 } }}>
			<Grid item xs={11}>
				<Box display="flex" flexDirection={"row"}>
					<Box ml={1}>
						<Typography variant="subtitle2">Search by component:</Typography>
						<TextField
							variant="outlined"
							size="small"
							value={searchValue}
							onChange={handleSearchValueChange}
							placeholder="Search..."
							InputProps={{
								startAdornment: <SearchIcon fontSize="small" />,
								endAdornment: (
									<IconButton
										title="Clear"
										aria-label="Clear"
										size="small"
										onClick={handleSearchValueClear}
									>
										<ClearIcon fontSize="small" />
									</IconButton>
								),
							}}
							sx={{
								backgroundColor: "white",
								"& input": {
									fontSize: "0.875rem",
								},
							}}
						/>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={1}>
				{hasManifest && (
					<Tooltip title="Download a PDF">
						<IconButton
							onClick={handleDownload}
							sx={{
								color: "primary",
								float: "right",
								marginTop: "20px",
								marginRight: "10px",
							}}
						>
							<CloudDownloadIcon color="primary" />
						</IconButton>
					</Tooltip>
				)}
			</Grid>
		</Grid>
	);
};
