import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Checkbox,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Spinner } from "@unified-trials/arcane-ui-tool";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Environment, EnvironmentComponentStatePayload } from "../../api";
import { useApi } from "../../hooks";
import { useUser } from "../../hooks/useUser";
import { DATETIME_FORMAT, formatDate } from "../../utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 10,
		},
	},
};

export type EnvironmentSelectorProps = {
	setEnvironment: React.Dispatch<React.SetStateAction<Environment | undefined>>;
	filteredApplications: string[];
	envComponents?: EnvironmentComponentStatePayload;
	setFilteredApplications: React.Dispatch<React.SetStateAction<string[]>>;
};

export const EnvironmentSelector = ({
	setEnvironment,
	filteredApplications,
	envComponents,
	setFilteredApplications,
}: EnvironmentSelectorProps) => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const { isLoading, listEnvironments, getManifestServiceNames } = useApi();
	const [selectedEnv, setSelectedEnv] = useState<string>("");
	const [environments, setEnvironments] = useState<Environment[]>();

	const [applications, setApplications] = React.useState<string[]>([]);

	const { isAdminUser } = useUser();

	useEffect(() => {
		const fetchData = async () => {
			const environmentsResult = await listEnvironments();
			if (
				environmentsResult.data?.length &&
				environmentsResult.data.length > 0
			) {
				setEnvironments(environmentsResult.data);
				if (state?.environmentId) {
					const env = environmentsResult.data.find(
						(e) => e._id === state.environmentId
					);
					if (env) {
						setSelectedEnv(env._id);
						setEnvironment(env);
					}
				} else {
					setSelectedEnv(environmentsResult.data[0]._id);
					setEnvironment(environmentsResult.data[0]);
				}
			}
		};

		fetchData();
	}, [state]);

	const fetchApplications = async () => {
		const manifestServiceNamesResult = await getManifestServiceNames();
		if (manifestServiceNamesResult.data) {
			setApplications(manifestServiceNamesResult.data);
			if (manifestServiceNamesResult.data.length > 0)
				setFilteredApplications([manifestServiceNamesResult.data[0]]);
		}
	};

	useEffect(() => {
		fetchApplications();
	}, []);

	const handleEnvironmentChange = (event: SelectChangeEvent) => {
		if (environments) {
			const env = environments.find((e) => e._id === event.target.value);
			setSelectedEnv(env?._id ?? "");
			setEnvironment(env);
		}
	};

	const handleApplicationChange = (event: SelectChangeEvent<string[]>) => {
		const {
			target: { value },
		} = event;
		const selected = typeof value === "string" ? value.split(",") : value;

		setFilteredApplications(selected);
	};

	return (
		<Paper sx={{ my: 2 }}>
			<Grid container>
				{isLoading && <Spinner fullscreen opacity={0.5} />}
				<Grid
					item
					xs={12}
					sm={6}
					px={3}
					sx={{
						borderRight: "0.1em solid lightgray",
						borderBottom: "0.1em solid lightgray",
					}}
				>
					<Box display={"flex"} alignItems={"center"}>
						<FormControl variant="standard" sx={{ my: 2, flexGrow: 1 }}>
							<InputLabel id="select-environment-label">Environment</InputLabel>
							<Select
								labelId="select-environment-label"
								value={selectedEnv}
								onChange={handleEnvironmentChange}
								label="Environment"
								data-testid={"environment-select"}
							>
								{environments?.map((e) => (
									<MenuItem key={e._id} value={e._id}>
										{e.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{isAdminUser && (
							<Tooltip title="Create new environment">
								<IconButton
									sx={{
										color: "primary",
										float: "right",
										marginLeft: "auto",
										alignItems: "center",
										height: "40px",
									}}
									onClick={() => navigate("/environments/create")}
								>
									<AddCircleIcon color="primary" />
								</IconButton>
							</Tooltip>
						)}
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
					px={3}
					sx={{ borderBottom: "0.1em solid lightgray" }}
				>
					<FormControl
						variant="standard"
						sx={{ my: 2, width: "100%", float: "right" }}
					>
						<InputLabel id="application-select-label">Application</InputLabel>
						<Select
							labelId="application-select-label"
							id="application-select"
							multiple
							value={filteredApplications}
							onChange={handleApplicationChange}
							renderValue={(selected) => selected.join(", ")}
							MenuProps={MenuProps}
							data-testid={"application-select"}
						>
							{applications.map((a) => (
								<MenuItem key={a} value={a}>
									<Checkbox checked={filteredApplications.indexOf(a) > -1} />
									<ListItemText primary={a} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={8} sx={{ p: { xs: 1, sm: 1 } }}>
					{envComponents && (
						<Grid
							container
							sx={{ fontSize: "0.8em" }}
							ml={1}
							alignItems={"center"}
						>
							<Grid item xs={12}>
								Last drift detection:{" "}
								<b>
									{envComponents.lastDriftDetection
										? formatDate(
												new Date(envComponents.lastDriftDetection),
												DATETIME_FORMAT
											)
										: "N/A"}
								</b>
							</Grid>
						</Grid>
					)}
				</Grid>
				{envComponents && (
					<Grid item xs={12}>
						<Accordion sx={{ backgroundColor: "whitesmoke" }}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1-content"
								id="panel1-header"
							>
								Manifest Details
							</AccordionSummary>
							<AccordionDetails>
								{envComponents.manifests.map((ec, index) => (
									<Box key={ec.manifestVersion}>
										<Grid container key={ec.manifestVersion} m={1}>
											<Grid item xs={12} md={4}>
												Manifest version: <b>{ec.manifestVersion}</b>
											</Grid>
											<Grid item xs={12} md={4}>
												Created on:{" "}
												<b>
													{formatDate(new Date(ec.createdAt), DATETIME_FORMAT)}
												</b>
											</Grid>
											<Grid item xs={12} md={4}>
												Last updated:{" "}
												<b>
													{formatDate(new Date(ec.updatedAt), DATETIME_FORMAT)}
												</b>
											</Grid>
										</Grid>
										{index !== envComponents.manifests.length - 1 && (
											<Divider />
										)}
									</Box>
								))}
							</AccordionDetails>
						</Accordion>
					</Grid>
				)}
			</Grid>
		</Paper>
	);
};
