import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
	Box,
	Container,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { Environment, ManifestBasic, ManifestHistory } from "../../api";
import { ManifestRow } from "../../components/manifests/list/ManifestRow";
import { filterManifests } from "../../components/manifests/list/manifestsListUtils";
import { useApi } from "../../hooks";
import { ManifestsListFilters } from "../../types";

export const ListManifestPage = () => {
	const {
		isLoading,
		listManifests,
		listManifestRevisions,
		getManifestRevisionByRevisionNo,
		listEnvironments,
	} = useApi();
	const [manifests, setManifests] = useState<ManifestBasic[]>([]);
	const [expanded, setExpanded] = React.useState<string | false>(false);
	const [manifestHistories, setManifestHistories] = useState<ManifestHistory[]>(
		[]
	);
	const [environments, setEnvironments] = useState<Environment[]>([]);

	const [componentJsonViewData, setComponentJsonViewData] = useState<unknown>();
	const [openComponentJsonView, setOpenComponentJsonView] = useState(false);
	const [jsonViewTitle, setJsonViewTitle] = useState<string>("");

	const handleChange =
		(panel: string) =>
		async (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
			const manifestHistoriesResult = await listManifestRevisions(panel);
			if (
				manifestHistoriesResult.data?.length &&
				manifestHistoriesResult.data.length > 0
			) {
				setManifestHistories(manifestHistoriesResult.data);
			}
		};

	const handleManifestViewClick =
		(id: string, revision: number) => async () => {
			const manifestResult = await getManifestRevisionByRevisionNo(
				id,
				revision
			);
			if (manifestResult.data) {
				setComponentJsonViewData(manifestResult.data.services);
				setJsonViewTitle(manifestResult.data.version);
				setOpenComponentJsonView(true);
			}
		};

	const handleManifestViewClose = () => {
		setOpenComponentJsonView(false);
	};

	const [searchByVersion, setSearchByVersion] = useState<string>("");
	const [filters, setFilters] = useState<ManifestsListFilters>({
		searchByVersion: "",
	});
	const updateFilters = (newFilters: Partial<ManifestsListFilters>) => {
		setFilters({
			searchByVersion,
			...newFilters,
		});
	};

	const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setSearchByVersion(newValue);

		updateFilters({ searchByVersion: newValue });
	};

	const handleSearchValueClear = () => {
		setSearchByVersion("");
		updateFilters({ searchByVersion: "" });
	};

	const visibleRows = useMemo(
		() => filterManifests(manifests, filters),
		[manifests, filters]
	);

	useEffect(() => {
		const fetchData = async () => {
			const [manifestsResult, environmentsResult] = await Promise.all([
				listManifests(),
				listEnvironments(),
			]);

			if (manifestsResult.data?.length && manifestsResult.data.length > 0) {
				setManifests(manifestsResult.data);
			}
			if (
				environmentsResult.data?.length &&
				environmentsResult.data.length > 0
			) {
				setEnvironments(environmentsResult.data);
			}
		};

		fetchData();
	}, []);

	return (
		<Container>
			<Grid container>
				<Grid item xs={12}>
					<Box m={1}>
						<Typography variant="subtitle2">Search by version:</Typography>
						<TextField
							variant="outlined"
							size="small"
							value={searchByVersion}
							onChange={handleSearchValueChange}
							placeholder="Search..."
							InputProps={{
								startAdornment: <SearchIcon fontSize="small" />,
								endAdornment: (
									<IconButton
										title="Clear"
										aria-label="Clear"
										size="small"
										onClick={handleSearchValueClear}
									>
										<ClearIcon fontSize="small" />
									</IconButton>
								),
							}}
							sx={{
								backgroundColor: "white",
								"& input": {
									fontSize: "0.875rem",
								},
								width: { xs: "100%", sm: "25%" },
							}}
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box>
						{visibleRows.map((x) => (
							<ManifestRow
								key={x.id}
								componentJsonViewData={componentJsonViewData}
								environments={environments}
								id={x.id}
								isExpanded={x.id === expanded}
								isLoading={isLoading}
								jsonViewTitle={jsonViewTitle}
								manifestHistories={manifestHistories}
								onChange={handleChange}
								onManifestViewClick={handleManifestViewClick}
								openComponentJsonView={openComponentJsonView}
								version={x.version}
								onCloseManifestView={handleManifestViewClose}
							></ManifestRow>
						))}
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};
