import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
	Checkbox,
	FormControl,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Stages } from "../../api";
import { useUser } from "../../hooks/useUser";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const stageKeys = Object.values(Stages);

export type FilterProps = {
	filteredStages: string[];
	filteredApplications: string[];
	handleStageChange: (event: SelectChangeEvent<string[]>) => void;
	handleApplicationChange: (event: SelectChangeEvent<string[]>) => void;
	applications: string[];
};
export const Filter = (props: FilterProps) => {
	const navigate = useNavigate();
	const { isAdminUser } = useUser();
	return (
		<Grid container spacing={2} p={2}>
			<Grid item xs={12} md={3}>
				<FormControl sx={{ width: "100%" }}>
					<InputLabel id="stage-select-label">Stage</InputLabel>
					<Select
						labelId="stage-select-label"
						id="stage-select"
						multiple
						value={props.filteredStages}
						onChange={props.handleStageChange}
						input={<OutlinedInput label="Stage" />}
						renderValue={(selected) => selected.join(", ")}
						MenuProps={MenuProps}
						data-testid={"stage-select"}
					>
						{stageKeys.map((s) => (
							<MenuItem key={s} value={s}>
								<Checkbox checked={props.filteredStages.indexOf(s) > -1} />
								<ListItemText primary={s} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={3}>
				<FormControl sx={{ width: "100%" }}>
					<InputLabel id="application-select-label">Application</InputLabel>
					<Select
						labelId="application-select-label"
						id="application-select"
						multiple
						value={props.filteredApplications}
						onChange={props.handleApplicationChange}
						input={<OutlinedInput label="Application" />}
						renderValue={(selected) => selected.join(", ")}
						MenuProps={MenuProps}
						data-testid={"application-select"}
					>
						{props.applications.map((a) => (
							<MenuItem key={a} value={a}>
								<Checkbox
									checked={props.filteredApplications.indexOf(a) > -1}
								/>
								<ListItemText primary={a} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} md={6}>
				{isAdminUser && (
					<Tooltip title="Create new environment">
						<IconButton
							sx={{
								color: "primary",
								float: { xs: "left", md: "right" },
								p: 0,
							}}
							onClick={() => navigate("/environments/create")}
						>
							<AddCircleIcon fontSize={"large"} color="primary" />
						</IconButton>
					</Tooltip>
				)}
			</Grid>
		</Grid>
	);
};
