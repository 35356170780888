import { Box, SelectChangeEvent } from "@mui/material";
import { Spinner } from "@unified-trials/arcane-ui-tool";
import React, { useEffect, useState } from "react";

import { Environment } from "../../api";
import { EnvironmentView } from "../../components";
import { useApi } from "../../hooks";
import { showNotification } from "../../utils";

interface Dictionary<T> {
	[Key: string]: T;
}

const getArrayFromLocalStorage = (key: string): string[] => {
	const storedArray = localStorage.getItem(key);
	if (storedArray) {
		return JSON.parse(storedArray) as string[];
	}
	return [];
};

const FILTERED_STAGES = "FILTERED_STAGES";
const FILTERED_APPLICATIONS = "FILTERED_APPLICATIONS";
export const EnvironmentViewPage = () => {
	const {
		isLoading,
		listEnvironments,
		getManifestServiceNames,
		deleteEnvironment,
	} = useApi();
	const [environments, setEnvironments] = useState<Environment[]>([]);
	const [filteredStages, setFilteredStages] = React.useState<string[]>(
		getArrayFromLocalStorage(FILTERED_STAGES)
	);
	const [filteredApplications, setFilteredApplications] = React.useState<
		string[]
	>(getArrayFromLocalStorage(FILTERED_APPLICATIONS));

	const handleStageChange = (event: SelectChangeEvent<string[]>) => {
		const {
			target: { value },
		} = event;

		const selected = typeof value === "string" ? value.split(",") : value;
		localStorage.setItem(FILTERED_STAGES, JSON.stringify(selected));

		setFilteredStages(selected);
	};

	const handleApplicationChange = (event: SelectChangeEvent<string[]>) => {
		const {
			target: { value },
		} = event;
		const selected = typeof value === "string" ? value.split(",") : value;
		localStorage.setItem(FILTERED_APPLICATIONS, JSON.stringify(selected));

		setFilteredApplications(selected);
	};

	const [maxEnvironmentCount, setMaxEnvironmentCount] = useState<number>(0);

	const [applications, setApplications] = React.useState<string[]>([]);

	const prepareEnvironmentsData = (environmentsData: Environment[]) => {
		setEnvironments(environmentsData);
		const environmentCounts = environmentsData.reduce<Dictionary<number>>(
			(acc, x) => {
				const { stage } = x;
				acc[stage] = acc[stage] ? acc[stage] + 1 : 1;
				return acc;
			},
			{}
		);

		const max = Object.values(environmentCounts).reduce(
			(maxCount, count) => Math.max(maxCount, count),
			0
		);
		setMaxEnvironmentCount(max);
	};
	const fetchEnvironments = async () => {
		const environmentsResult = await listEnvironments();
		if (environmentsResult.data) {
			prepareEnvironmentsData(environmentsResult.data);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const [manifestServiceNamesResult, environmentsResult] =
				await Promise.all([getManifestServiceNames(), listEnvironments()]);

			if (manifestServiceNamesResult.data) {
				setApplications(manifestServiceNamesResult.data);
			}

			if (environmentsResult.data) {
				prepareEnvironmentsData(environmentsResult.data);
			}
		};
		fetchData();
	}, []);

	const handleDelete = async (id: string, comment?: string) => {
		const { error } = await deleteEnvironment(id, comment);
		if (!error) {
			showNotification("success", `Environment successfully deleted`);
			await fetchEnvironments();
		}
	};
	return (
		<Box>
			{isLoading && <Spinner fullscreen opacity={0.5} />}
			<EnvironmentView
				environments={environments}
				filteredStages={filteredStages}
				filteredApplications={filteredApplications}
				handleStageChange={handleStageChange}
				handleApplicationChange={handleApplicationChange}
				maxEnvironmentCount={maxEnvironmentCount}
				applications={applications}
				handleDelete={handleDelete}
			></EnvironmentView>
		</Box>
	);
};
