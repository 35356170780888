import { SortingOrder } from "../types";

const descendingComparator = <T>(a: T, b: T) => {
	if (b < a) {
		return -1;
	}
	if (b > a) {
		return 1;
	}
	return 0;
};

export const getComparator = (
	order: SortingOrder
): ((a?: number | string, b?: number | string) => number) => {
	return order === "desc"
		? (a, b) => descendingComparator(a, b)
		: (a, b) => -descendingComparator(a, b);
};
