import {
	ApiResponse,
	useFetchWithMsal,
} from "@unified-trials/arcane-sso-react";

import {
	GetEnvironmentComponentsResponse,
	GetEnvironmentResponse,
	GetManifestResponse,
	GetManifestRevisionByRevisionNo,
	ListEnvironmentsResponse,
	ListManifestRevisionsResponse,
	ListManifestsResponse,
} from "../api";
import {
	EditEnvironmentPayload,
	EditEnvironmentResponse,
} from "../api/interfaces/environment/patch";
import {
	CreateEnvironment,
	CreateEnvironmentResponse,
} from "../api/interfaces/environment/put";
import { displayError } from "../utils";

export const useApi = () => {
	const { get, isLoading, post, put, patch, del } =
		useFetchWithMsal(displayError);

	return {
		isLoading,
		listEnvironments: async (): Promise<
			ApiResponse<ListEnvironmentsResponse>
		> => {
			return get<ListEnvironmentsResponse>("/environments");
		},
		listManifestRevisions: async (
			id: string
		): Promise<ApiResponse<ListManifestRevisionsResponse>> => {
			return get<ListManifestRevisionsResponse>(`/manifests/${id}/revisions`);
		},
		getEnvComponentsByService: async (
			environment: string,
			services: string[],
			revision?: number
		): Promise<ApiResponse<GetEnvironmentComponentsResponse>> => {
			let query: string = `?services=${services.join(",")}`;
			if (revision) query = `&revision=${revision}`;

			return get<GetEnvironmentComponentsResponse>(
				`/environments/components/${environment}${query}`
			);
		},
		getManifestDocument: async (
			environment: string,
			services: string[]
		): Promise<ApiResponse<string>> => {
			const query: string = `?services=${services.join(",")}`;

			return get<string>(`/documents/environments/${environment}${query}`);
		},
		getManifest: async (
			id: string
		): Promise<ApiResponse<GetManifestResponse>> => {
			return get<GetManifestResponse>(`/manifests/${id}`);
		},
		postDriftDetectionWorkflow: async (
			id: string
		): Promise<ApiResponse<boolean>> => {
			return post(`/drift-detection-workflow/environment/${id}`);
		},
		getManifestServiceNames: async (): Promise<ApiResponse<string[]>> => {
			return get<string[]>("/manifests/service-names");
		},
		createEnvironment: async (
			data: CreateEnvironment
		): Promise<ApiResponse<CreateEnvironmentResponse>> => {
			return put<CreateEnvironment, CreateEnvironmentResponse>(
				"/environments",
				data
			);
		},
		listManifests: async (): Promise<ApiResponse<ListManifestsResponse>> => {
			return get<ListManifestsResponse>(`/manifests`);
		},
		editEnvironment: async (
			id: string,
			data: EditEnvironmentPayload
		): Promise<ApiResponse<EditEnvironmentResponse>> => {
			return patch<EditEnvironmentPayload, EditEnvironmentResponse>(
				`/environments/${id}`,
				data
			);
		},
		getEnvironment: async (
			id: string
		): Promise<ApiResponse<GetEnvironmentResponse>> => {
			return get<GetEnvironmentResponse>(`/environments/${id}`);
		},
		getManifestRevisionByRevisionNo: async (
			id: string,
			revision: number
		): Promise<ApiResponse<GetManifestRevisionByRevisionNo>> => {
			return get<GetManifestRevisionByRevisionNo>(
				`/manifests/${id}/revisions/${revision}`
			);
		},
		deleteEnvironment: async (
			id: string,
			comment?: string
		): Promise<ApiResponse<null>> => {
			let query: string = "";
			if (comment) query = `?comment=${comment}`;

			return del(`/environments/${id}${query}`);
		},
	};
};
