import { useAuth } from "@unified-trials/arcane-sso-react";

import { UserRoles } from "../constants/userRoles";


export const useUser = () => {
	const { roles } = useAuth();

	const isAdminUser = !!(roles && roles.includes(UserRoles.ADMIN));

	return {
		isAdminUser,
	};
};
