import { Container, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { BackButton, Spinner } from "@unified-trials/arcane-ui-tool";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GetEnvironment, ManifestBasic } from "../../api";
import { EditEnvironmentForm } from "../../components/environments/EditEnvironmentForm";
import { useApi } from "../../hooks";

export const EditEnvironmentPage: React.FC = () => {
	const navigate = useNavigate();
	const { id } = useParams<"id">();
	const [manifests, setManifests] = useState<ManifestBasic[]>();
	const [environment, setEnvironment] = useState<GetEnvironment>();
	const { isLoading, getEnvironment, listManifests } = useApi();

	useEffect(() => {
		if (!id) {
			navigate("/404");
			return;
		}
		const fetchData = async () => {
			const [manifestsResult, environmentResult] = await Promise.all([
				listManifests(),
				getEnvironment(id),
			]);

			if (manifestsResult.data?.length && manifestsResult.data.length > 0) {
				setManifests(manifestsResult.data);
			}

			if (environmentResult.data) {
				setEnvironment(environmentResult.data);
			}
		};
		fetchData();
	}, []);

	return (
		<Box>
			{isLoading && <Spinner fullscreen opacity={0.5} />}

			{!!environment && !!manifests && !!id && (
				<Container sx={{ marginBottom: 2 }}>
					<Stack direction="row" spacing={2} my={1} alignItems="center">
						<BackButton href="/environments" />
						<Typography variant="h4" my={2}>
							Edit {environment.name}
						</Typography>
					</Stack>
					<EditEnvironmentForm
						environmentId={id}
						environment={environment}
						manifests={manifests}
					></EditEnvironmentForm>
				</Container>
			)}
		</Box>
	);
};
